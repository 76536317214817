import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    Grid,
    IconButton,
    Drawer,
    List,
    ListItem,
    useMediaQuery,
    useTheme,
    Divider,
    Slide,
    useScrollTrigger,
    Menu,
    MenuItem,
    Link as MuiLink,
    Avatar,
} from "@mui/material";
import { useUser, UserContext } from '../../contexts/UserContext';
import { Link, navigate } from "gatsby";
import { Menu as MenuIcon, MoreVert as MoreVertIcon, AccountCircle } from "@mui/icons-material";
import LayoutType from "../../constants/layout-type";
import Footer from "./footer";
import Colors from "../../constants/colors";
import { FormattedMessage } from 'react-intl';
import { LanguageContext } from "../../contexts/LanguageContext";
import LanguagePicker from "../language/language-picker";
import { auth } from '../../firebase/config';
import { onAuthStateChanged } from "firebase/auth";
import { green } from "@mui/material/colors";
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { NoSsr } from '@mui/base';


const HideOnScroll: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

const Header: React.FC<{ layoutType: LayoutType, maxWidth: string }> = ({ layoutType, maxWidth }) => {
    const { locale, setLocale } = useContext(LanguageContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const route = typeof window !== "undefined" ? window.location.pathname : "";
    const isHomeRoute = (!isAuthenticated && (route === "/" || route === "/index.html")) || (isAuthenticated && (route === "/app/dashboard/"));
    const { user, setUser } = useUser();

    const [transparentHeader, setTransparentHeader] = React.useState(
        false //layoutType === LayoutType.ImageHeading || layoutType === LayoutType.ImageHeadingCentered
    );

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            const isAuthenticated = !!user;
            setIsAuthenticated(isAuthenticated);

            if (user) {
                setUser({
                    uid: user.uid,
                    displayName: user.displayName,
                    emailAddress: user.email,
                    emailVerified: user.emailVerified
                });

                // Check if path is under /app and user's email is not verified
                if (!user.emailVerified && route.startsWith('/app/')) {
                    navigate('/auth/confirm-email/');
                }
            } else {
                // Redirect to login if user is not authenticated and trying to access /app routes
                if (route.startsWith('/app/')) {
                    navigate('/auth/login/');
                }
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [route]);

    const getInitialFromName = (name: string | null) => {
        return name ? name[0] : 'U'; // 'U' as a fallback for 'Unknown'
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const renderAuthenticatedMenu = () => (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            {/* User info and avatar */}
            {user && (
                <Box sx={{ p: 2 }} display="flex" flexDirection="row" alignItems="center">
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: green[500] }}>
                            {getInitialFromName(user.displayName)}
                        </Avatar>
                    </ListItemAvatar>
                    <Box>
                        <Typography variant="subtitle1" sx={{fontWeight:'bold'}} noWrap>
                            {user.displayName}
                        </Typography>
                        <Typography variant="body2" noWrap>
                            {user.emailAddress}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Divider />
            {/* Menu items with icons */}
            <MenuItem onClick={() => { navigate('/app/dashboard'); handleMenuClose(); }} sx={{ p: 2, m: 0 }}>
                <Typography flex={1} variant="inherit">Dashboard</Typography>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
            </MenuItem>
            <MenuItem onClick={() => { navigate('/app/account'); handleMenuClose(); }} sx={{ p: 2, m: 0 }}>
                <Typography flex={1} variant="inherit">Account</Typography>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
            </MenuItem>
            <MenuItem onClick={() => { auth.signOut(); navigate('/'); handleMenuClose(); }} sx={{ p: 2, m: 0 }}>
                <Typography flex={1} variant="inherit">Sign Out</Typography>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
            </MenuItem>
        </Menu>
    );

    const renderLinks = () => (
        <>
            <MuiLink color="inherit" component={Link} to="/app-setup" sx={{ mx: 2 }}>
                <FormattedMessage id="links.setupInstructions" defaultMessage="Setup Instructions" />
            </MuiLink>
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            <MuiLink color="inherit" component={Link} to="/digital-signage-devices" sx={{ mx: 2 }}>
                <FormattedMessage id="links.digitalSignageDevices" defaultMessage="Digital Signage Devices" />
            </MuiLink>
            &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Box flexGrow={1} />
            <LanguagePicker currentLocale={locale} onLanguageChange={setLocale} />
            {isAuthenticated ? (
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    color="inherit"
                >
                    <Avatar sx={{ bgcolor: green[500] }}>
                        {user ? getInitialFromName(user.displayName) : 'N'}
                    </Avatar>
                </IconButton>
            ) : (
                <>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <MuiLink component={Link} to="/auth/login" color="inherit">
                        <FormattedMessage id="links.signIn" defaultMessage="Sign In" />
                    </MuiLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <MuiLink component={Link} to="/auth/register" color="inherit">
                        <FormattedMessage id="links.register" defaultMessage="Register" />
                    </MuiLink>
                </>
            )}
        </>
    );

    const drawerLinks = () => (
        <List>
            {
                isAuthenticated ? (
                    <>
                        {user && (
                            <Box sx={{ p: 2, pb: 3 }} display="flex" flexDirection="row" alignItems="center">
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: green[500] }}>
                                        {getInitialFromName(user.displayName)}
                                    </Avatar>
                                </ListItemAvatar>
                                <Box>
                                    <Typography variant="subtitle1" sx={{fontWeight:'bold'}} noWrap>
                                        {user.displayName}
                                    </Typography>
                                    <Typography variant="body2" noWrap>
                                        {user.emailAddress}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        <Divider />
                        <ListItem button component={Link} to="/app/dashboard" sx={{ p: 2, pr: 0, mt: 2 }}>
                            <Typography flex={1} variant="h5" sx={{ fontSize: '20px' }}>
                                <FormattedMessage id="links.dashboard" defaultMessage="Dashboard" />
                            </Typography>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem button component={Link} to="/app/account" sx={{ p: 2, pr: 0 }}>
                            <Typography flex={1} variant="h5" sx={{ fontSize: '20px' }}>
                                <FormattedMessage id="links.account" defaultMessage="Account" />
                            </Typography>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem button component={Link} to="/" onClick={() => { auth.signOut(); }} sx={{ p: 2, pr: 0, m: 0 }}>
                            <Typography flex={1} variant="h5" sx={{ fontSize: '20px' }}>
                                <FormattedMessage id="links.signOut" defaultMessage="Sign Out" />
                            </Typography>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                        </ListItem>
                        <Divider sx={{ pt: 2, pb: 1 }} />
                        <Box sx={{ pb: 2 }}></Box>
                    </>
                ) : null
            }
            <ListItem button component={Link} to="/app-setup" sx={{ p: 2 }}>
                <Typography variant="h5" sx={{ fontSize: '20px' }}>
                    <FormattedMessage id="links.setupInstructions" defaultMessage="Setup Instructions" />
                </Typography>
            </ListItem>
            <ListItem button component={Link} to="/digital-signage-devices" sx={{ p: 2 }}>
                <Typography variant="h5" sx={{ fontSize: '20px' }}>
                    <FormattedMessage id="links.digitalSignageDevices" defaultMessage="Digital Signage Devices" />
                </Typography>
            </ListItem>
            {
                !isAuthenticated && <>
                    <ListItem button component={Link} to="/auth/login" sx={{ p: 2 }}>
                        <Typography variant="h5" sx={{ fontSize: '20px' }}>
                            <FormattedMessage id="links.signIn" defaultMessage="Sign In" />
                        </Typography>
                    </ListItem>
                    <ListItem button component={Link} to="/auth/register" sx={{ p: 2 }}>
                        <Typography variant="h5" sx={{ fontSize: '20px' }}>
                            <FormattedMessage id="links.register" defaultMessage="Register" />
                        </Typography>
                    </ListItem>
                </>
            }
            <Divider sx={{ pt: 2, pb: 2 }} />
            <ListItem
                sx={{ mt: 2 }}
            >
                <Button
                    variant="outlined"
                    color="inherit"
                    href="https://play.google.com/store/apps/details?id=com.screenkeep.screenkeep"
                >
                    <FormattedMessage id="links.downloadButtonNoTitle" defaultMessage="Download On Google Play" />
                </Button>
            </ListItem>
        </List>
    );

    return (
        <HideOnScroll>
            <AppBar
                position="fixed"
                sx={
                    transparentHeader
                        ? { backgroundColor: "transparent", color: "white", boxShadow: "none" }
                        : { backgroundColor: Colors.BACKGROUND_COLOR_DARK, color: Colors.BACKGROUND, boxShadow: "none" }
                }
                elevation={0}
            >
                <Container maxWidth={maxWidth}>
                    <Toolbar sx={{
                        marginLeft: !isMobile ? "-24px" : "-18px",
                        marginRight: !isMobile ? "-24px" : "-18px"
                    }}>
                        {(!isHomeRoute || isMobile) &&
                            <Typography variant="h6" component="div" paddingTop="24px" paddingRight="75px" sx={{ color: Colors.BACKGROUND }}>
                                <Link to={ isAuthenticated ? "/app/dashboard/" : "/" } style={{ textDecoration: "none", color: "inherit" }}>
                                    Screen Keep {isAuthenticated && !isPhone ? "Dashboard" : "" }
                                </Link>
                            </Typography>
                        }
                        {isMobile ? (
                            <>
                                <Box flexGrow={1} />

                                <LanguagePicker
                                    currentLocale={locale}
                                    aria-label="menu"
                                    onLanguageChange={setLocale} // Pass setLocale as a callback
                                /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <IconButton
                                    aria-label="Open Menu"
                                    edge="end"
                                    color="secondary"
                                    onClick={toggleDrawer(true)}>
                                    <MenuIcon />
                                </IconButton>
                                <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                                    <Box
                                        sx={{ width: 300 }}
                                        role="presentation"
                                        onClick={toggleDrawer(false)}
                                        onKeyDown={toggleDrawer(false)}
                                    >
                                        <List>
                                            {/* Drawer content for mobile */}
                                            {drawerLinks()}
                                        </List>
                                    </Box>
                                </Drawer>
                            </>
                        ) : renderLinks()}
                    </Toolbar>
                </Container>
                {renderAuthenticatedMenu()}
            </AppBar>
        </HideOnScroll>
    );
};

interface LayoutProps {
    defaultLayout: LayoutType;
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ defaultLayout, children }) => {
    const [isClient, setIsClient] = useState(false);
    const { setUser } = useContext(UserContext);
    const getContentMaxWidth = (layout: LayoutType | undefined) => {
        switch (layout) {
            case LayoutType.DefaultSmallContent:
                return "sm";
            case LayoutType.ImageHeadingCenteredFullWidth:
                return false;
            case LayoutType.DefaultFullWidth:
            case LayoutType.TvDefault:
                return false;
            default:
                return "lg";
        }
    };

    useEffect(() => {
        setIsClient(true);
        if (typeof window !== 'undefined') {
           window.screenKeepLoaded();
        }

        if (typeof window !== 'undefined') {
            const route = window.location.pathname;
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                setUser(user);
                if (route.startsWith('/app/') && !user) {
                    navigate('/auth/login/');
                } else if (route.startsWith('/app/') && user && !user.emailVerified) {
                    navigate('/auth/confirm-email/');
                }
            });


            return () => unsubscribe();
        }
    }, []);

    const ContainerComponent = defaultLayout === LayoutType.DefaultFullWidth ? Box : Container;
    const ShowHeader = defaultLayout !== LayoutType.Headless && defaultLayout !== LayoutType.TvDefault;
    const ShowFooter = defaultLayout !== LayoutType.Headless && defaultLayout !== LayoutType.TvDefault;
    const FullWidth = defaultLayout === LayoutType.DefaultFullWidth || defaultLayout === LayoutType.TvDefault;

    return (
        <Box minHeight="100vh" display="flex" flexDirection="column">
            <NoSsr>
                {ShowHeader && <Header layoutType={defaultLayout} maxWidth={FullWidth ? getContentMaxWidth(LayoutType.Default) : getContentMaxWidth(defaultLayout)} />}
                <Box flexGrow={1}>
                    <ContainerComponent sx={{
                        maxWidth: FullWidth ? null : getContentMaxWidth(defaultLayout),
                        overflow: 'hidden'
                    }}>
                        <main>{children}</main>
                    </ContainerComponent>
                </Box>
                {ShowFooter && <Footer />}
            </NoSsr>
        </Box>
    );
};

export default Layout;