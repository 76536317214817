import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "gatsby";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Colors from "../../constants/colors";
import { StaticImage } from "gatsby-plugin-image";

const Footer: React.FC = () => {
    return (
        <Box mt={10} py={3} bgcolor="primary.main" color="white" position="relative" bottom={0}>
            <Container maxWidth="md">
                <Grid container justifyContent="center" pt={1}>
                    <Grid item xs={12} textAlign="center">
                        <StaticImage
                            src="../../images/icon.png"
                            alt="Icon"
                            placeholder="blurred"
                            layout="fixed"
                            loading="lazy"
                            style={{
                                marginBottom: '1rem',
                                borderRadius: '15px',
                                width: 100,
                                height: 100,
                                display: 'inline-block'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="body2" align="center" display="inline-block">
                            Copyright &copy; {new Date().getFullYear()} Screen Keep
                            <br />
                            <FormattedMessage id="copyright" defaultMessage="All Rights Reserved" />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} pt={3} pb={5} textAlign="center">
                        <Typography variant="body2" align="center" display="inline-block">
                            <Link to="/legal/privacy-policy" style={{ textDecoration: "none", color: Colors.BACKGROUND }}>
                                <FormattedMessage id="links.privacyPolicy" defaultMessage="Privacy Policy" />
                            </Link>
                            {" | "}
                            <Link to="/legal/terms-of-use" style={{ textDecoration: "none", color: Colors.BACKGROUND }}>
                                <FormattedMessage id="links.termsOfUse" defaultMessage="Terms of Use" />
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
