import en from './translations/en.json';
import es from './translations/es.json';
import fr from './translations/fr.json';
import de from './translations/de.json';

export const translations = {
    'en': en,
    'es': es,
    'fr': fr,
    'de': de
};