import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyDDrVKVDyFww3j5MGTyXkmfqQ1C2EktuyQ",
    authDomain: "screen-keep.firebaseapp.com",
    projectId: "screen-keep",
    storageBucket: "screen-keep.appspot.com",
    messagingSenderId: "860604164864",
    appId: "1:860604164864:web:bfa077207a2e8af096a1e5",
    measurementId: "G-5L8KREGMLS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firebase Auth and Firestore instances
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db, sendPasswordResetEmail, functions };