// src/theme.ts
import { createTheme } from "@mui/material";
import Colors from "./constants/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: Colors.PRIMARY,
        },
        secondary: {
            main: Colors.SECONDARY,
        },
        background: {
            default: Colors.BACKGROUND,
        },
    },
    typography: {
        fontFamily: "Android Euclid, Roboto, Helvetica, Arial, sans-serif",
        h1: {
            fontSize: "4.25rem",
            lineHeight: "76px",
            letterSpacing: "-2px",
            fontWeight: 700,
            marginBottom: "48px",
            marginTop: "0",
            maxWidth: "90%",
        },
        h2: {
            fontSize: "3.75rem",
            lineHeight: "68px",
            letterSpacing: "-2px",
            fontWeight: 700,
        },
        h3: {
            fontSize: "3rem",
            lineHeight: "54px",
            letterSpacing: "-1px",
            fontWeight: 700,
        },
        h4: {
            fontSize: "2.125rem",
            lineHeight: "36px",
            letterSpacing: "-0.5px",
            fontWeight: 700,
        },
        h5: {
            fontSize: "1.5rem",
            lineHeight: "32px",
            letterSpacing: "0",
            fontWeight: 700,
        },
        h6: {
            fontSize: "1rem",
            lineHeight: "16px",
            letterSpacing: "0.5px",
            fontWeight: 500,
            marginBottom: "24px",
            color: "#000",
            textTransform: "uppercase",
        },
        body1: {
            fontSize: "1.25rem",
            lineHeight: "32px",
            letterSpacing: "0",
            fontWeight: 400,
        },
        body2: {
            fontSize: "1rem",
            lineHeight: "24px",
            letterSpacing: "0.5px",
            fontWeight: 400,
        },
        button: {
            textTransform: "none",
            fontWeight: 600,
            letterSpacing: "0.03125rem",
        },
    },
    shape: {
        borderRadius: 20,
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 40, // Match provided styles
                    padding: "16px 32px", // Match provided styles
                    minWidth: 80,
                    lineHeight: "1.5rem",
                    transition: "color 0.3s linear",
                    zIndex: 1,
                    margin: 0,
                    "&:hover": {
                        backgroundColor: "#6dd58c", // Match provided hover styles
                        color: "#073042", // Match provided hover styles
                        textDecoration: "none",
                        transition: "background-color 0s linear 300ms, color 300ms linear", // Match provided hover styles
                    },
                    focused: {
                        backgroundColor: Colors.SECONDARY,
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: Colors.SECONDARY,
                        },
                    },
                },
                contained: {
                    boxShadow: "none",
                    "&:hover": {
                        boxShadow: "none",
                    },
                },
                outlined: {
                    borderColor: "rgba(0, 0, 0, 0.23)",
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "none",
            },
            styleOverrides: {
                root: {
                    display: "inline-block",
                    fontFamily: "Android Euclid, Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "1rem",
                    fontWeight: 500,
                    lineHeight: "1.5rem",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "var(--color-is-6)",
                    backgroundColor: "transparent",
                    borderRadius: "40px",
                    textDecoration: "none",
                    cursor: "pointer",
                    position: "inherit",
                    transition: "color .2s ease-in",
                    "&:hover": {
                        color: "#267e69",
                        transition: "color .2s ease-out",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: "none",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: "1em",
                },
            },
        },

        MuiFormControlLabel: {
            styleOverrides: {
                label: { // Targeting the label directly
                    marginBottom: '0 !important', // Using !important to ensure override
                },
                root: {
                    '& .MuiTypography-gutterBottom': {
                        marginBottom: '0', // Reset the margin for this specific case
                    },
                },
            },
        },

        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    // Ensure the typography used inside does not get unintended styles
                    '& .MuiTypography-root': {
                        marginBottom: '0px', // Override the margin-bottom for Typography inside InputAdornment
                    },
                },
                positionEnd: {
                    // Adjust this if you need specific styles for adornments at the end
                    marginRight: '8px', // Adjust the right margin if necessary
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: "dense",
            },
            styleOverrides: {
                root: {
                    marginTop: "8px",
                    marginBottom: "8px",
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: "dense",
            },
            styleOverrides: {
                root: {
                    marginTop: "8px",
                    marginBottom: "8px",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: "8px",
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: "8px",
                    paddingBottom: "8px",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingTop: "8px",
                    paddingBottom: "8px",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    backgroundColor: "#E0E0E0",
                },
            },
        },
    },
});

export default theme;
