exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-account-tsx": () => import("./../../../src/pages/app/account.tsx" /* webpackChunkName: "component---src-pages-app-account-tsx" */),
  "component---src-pages-app-billing-end-tsx": () => import("./../../../src/pages/app/billing-end.tsx" /* webpackChunkName: "component---src-pages-app-billing-end-tsx" */),
  "component---src-pages-app-dashboard-tsx": () => import("./../../../src/pages/app/dashboard.tsx" /* webpackChunkName: "component---src-pages-app-dashboard-tsx" */),
  "component---src-pages-app-loading-billing-tsx": () => import("./../../../src/pages/app/loading-billing.tsx" /* webpackChunkName: "component---src-pages-app-loading-billing-tsx" */),
  "component---src-pages-app-payment-cancel-tsx": () => import("./../../../src/pages/app/payment-cancel.tsx" /* webpackChunkName: "component---src-pages-app-payment-cancel-tsx" */),
  "component---src-pages-app-payment-success-tsx": () => import("./../../../src/pages/app/payment-success.tsx" /* webpackChunkName: "component---src-pages-app-payment-success-tsx" */),
  "component---src-pages-app-setup-tsx": () => import("./../../../src/pages/app-setup.tsx" /* webpackChunkName: "component---src-pages-app-setup-tsx" */),
  "component---src-pages-auth-confirm-email-tsx": () => import("./../../../src/pages/auth/confirm-email.tsx" /* webpackChunkName: "component---src-pages-auth-confirm-email-tsx" */),
  "component---src-pages-auth-direct-tsx": () => import("./../../../src/pages/auth/direct.tsx" /* webpackChunkName: "component---src-pages-auth-direct-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-auth-request-reset-tsx": () => import("./../../../src/pages/auth/request-reset.tsx" /* webpackChunkName: "component---src-pages-auth-request-reset-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-digital-signage-devices-tsx": () => import("./../../../src/pages/digital-signage-devices.tsx" /* webpackChunkName: "component---src-pages-digital-signage-devices-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-terms-of-use-tsx": () => import("./../../../src/pages/legal/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-tsx" */),
  "component---src-pages-tv-activation-required-tsx": () => import("./../../../src/pages/tv/activation-required.tsx" /* webpackChunkName: "component---src-pages-tv-activation-required-tsx" */),
  "component---src-pages-tv-welcome-tsx": () => import("./../../../src/pages/tv/welcome.tsx" /* webpackChunkName: "component---src-pages-tv-welcome-tsx" */)
}

