import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import CountryFlag from 'react-country-flag';

interface Language {
    locale: string;
    name: string;
    countryCode: string;
}

interface Props {
    currentLocale: string;
    onLanguageChange: (newLocale: string) => void;
}

const languages: Language[] = [
    //{
    //    locale: 'ar',
    //    name: 'العربية',
    //    countryCode: 'SA',
    //},
    //{
    //    locale: 'bn',
    //    name: 'বাংলা',
    //    countryCode: 'BD',
    //},
    {
        locale: 'de',
        name: 'Deutsch',
        countryCode: 'DE',
    },
    {
        locale: 'en',
        name: 'English',
        countryCode: 'US',
    },
    {
        locale: 'es',
        name: 'Español',
        countryCode: 'ES',
    },
    {
        locale: 'fr',
        name: 'Français',
        countryCode: 'FR',
    },
    //{
    //    locale: 'hi',
    //    name: 'हिन्दी',
    //    countryCode: 'IN',
    //},
    //{
    //    locale: 'ja',
    //    name: '日本語',
    //    countryCode: 'JP',
    //},
    //{
    //    locale: 'jv',
    //    name: 'Javanese',
    //    countryCode: 'ID',
    //},
    //{
    //    locale: 'ko',
    //    name: '한국어',
    //    countryCode: 'KR',
    //},
    //{
    //    locale: 'ms',
    //    name: 'Bahasa Melayu',
    //    countryCode: 'MY',
    //},
    //{
    //    locale: 'pt',
    //    name: 'Português',
    //    countryCode: 'PT',
    //},
    //{
    //    locale: 'ru',
    //    name: 'Русский',
    //    countryCode: 'RU',
    //},
    //{
    //    locale: 'sw',
    //    name: 'Kiswahili',
    //    countryCode: 'KE',
    //},
    //{
    //    locale: 'ta',
    //    name: 'தமிழ்',
    //    countryCode: 'IN',
    //},
    //{
    //    locale: 'te',
    //    name: 'తెలుగు',
    //    countryCode: 'IN',
    //},
    //{
    //    locale: 'vi',
    //    name: 'Tiếng Việt',
    //    countryCode: 'VN',
    //},
    //{
    //    locale: 'ur',
    //    name: 'اردو',
    //    countryCode: 'PK',
    //},
    //{
    //    locale: 'yo',
    //    name: 'Yorùbá',
    //    countryCode: 'NG',
    //},
    //{
    //    locale: 'zh-CN',
    //    name: '简体中文',
    //    countryCode: 'CN',
    //},
    //{
    //    locale: 'zh-TW',
    //    name: '繁體中文',
    //    countryCode: 'TW',
    //}
];


const LanguagePicker: React.FC<Props> = ({ currentLocale, onLanguageChange }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(languages.find(lang => lang.locale === currentLocale) || languages[0]);

    useEffect(() => {
        setSelectedLanguage(languages.find(lang => lang.locale === currentLocale) || languages[0]);
    }, [currentLocale]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (language: Language) => {
        setSelectedLanguage(language);
        setAnchorEl(null);
        onLanguageChange(language.locale); // Notify the parent about the change
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                edge="end"
                color="inherit"
                aria-label="change language"
                onClick={handleClick}
            >
                <CountryFlag
                    alt={selectedLanguage.name}
                    countryCode={selectedLanguage.countryCode}
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                        borderRadius: '50%',
                    }}
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {languages.map((language) => (
                    <MenuItem
                        key={language.locale}
                        onClick={() => handleMenuItemClick(language)}
                        sx={{
                            '& .MuiListItemButton-root': {
                                px: 1,
                                py: 2, 
                                borderRadius: 0
                            },
                            minWidth: '200px',
                            margin: 0,
                            pt: 2,
                            pb: 2
                        }}
                    >
                        <CountryFlag
                            countryCode={language.countryCode}
                            svg
                            style={{
                                width: '1em',
                                height: '1em',
                                marginRight: '10px',
                            }}
                        />
                        {language.name}
                    </MenuItem>
                ))}
            </Menu>

        </div>
    );
};

export default LanguagePicker;