import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import Layout from "./src/components/layout/layout"
import LayoutType from "./src/constants/layout-type"
import { ThemeProvider } from "@mui/material";
import theme from "./src/theme";
import "./src/global.css";
import { IntlProvider } from 'react-intl';
import { LanguageContext } from './src/contexts/LanguageContext';
import { useState, useEffect, useRef } from "react";
import { UserProvider } from "./src/contexts/UserContext";
import { translations } from './src/translations';

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {

    const layoutType = element.type.layoutType;
    const [locale, setLocale] = useState(localStorage.getItem('locale') || navigator.language.split('-')[0]);

    useEffect(() => {
        localStorage.setItem('locale', locale);
    }, [locale]);

    // Check if the locale is supported, if not, fall back to English
    if (!translations[locale]) {
        setLocale('en');
    }

    return (
        <LanguageContext.Provider value={{ locale, setLocale }}>
            <IntlProvider locale={locale} messages={translations[locale]}>
                <ThemeProvider theme={theme}>
                    <UserProvider>
                        <Layout defaultLayout={layoutType} {...props}>
                            {element}
                        </Layout>
                    </UserProvider>
                </ThemeProvider>
            </IntlProvider>
        </LanguageContext.Provider>
    );
};