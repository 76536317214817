import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { auth } from '../firebase/config'; // Adjust the import path as needed
import { onAuthStateChanged } from "firebase/auth";

// Define a type for the user information you plan to store
interface User {
    uid: string;
    displayName: string | null;
    emailAddress: string | null;
    emailVerified: boolean;
}

// Define a type for the context state
interface UserContextType {
    user: User | null;
    isAuthenticated: boolean;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

// Create the context with an undefined initial value but specify the type
export const UserContext = createContext<UserContextType | undefined>(undefined);

// Custom hook to use the user context
export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

interface UserProviderProps {
    children: ReactNode;
}

const localStorageRef = typeof window !== 'undefined' ? localStorage : null;
const windowRef = typeof window !== 'undefined' ? window : null;

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
            if (firebaseUser) {
                // User logs in
                setIsAuthenticated(true);
                setUser({
                    uid: firebaseUser.uid,
                    displayName: firebaseUser.displayName,
                    emailAddress: firebaseUser.email,
                    emailVerified: firebaseUser.emailVerified,
                });
                localStorageRef?.setItem('isLoggedIn', 'true'); // Set flag in localStorage
            } else {
                // User logs out
                setIsAuthenticated(false);
                setUser(null);
                localStorageRef?.removeItem('isLoggedIn'); // Remove flag from localStorage
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const syncAuthStateAcrossTabs = (event: StorageEvent) => {
            if (event.key === 'isLoggedIn') {
                if (event.newValue) {
                    // User logged in in another tab
                    onAuthStateChanged(auth, (firebaseUser) => {
                        if (firebaseUser) {
                            setIsAuthenticated(true);
                            setUser({
                                uid: firebaseUser.uid,
                                displayName: firebaseUser.displayName,
                                emailAddress: firebaseUser.email,
                                emailVerified: firebaseUser.emailVerified,
                            });
                        }
                    });
                } else {
                    // User logged out in another tab
                    setIsAuthenticated(false);
                    setUser(null);
                }
            }
        };

        windowRef?.addEventListener('storage', syncAuthStateAcrossTabs);

        return () => {
            windowRef?.removeEventListener('storage', syncAuthStateAcrossTabs);
        };
    }, []);


    return (
        <UserContext.Provider value={{ user, isAuthenticated, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
