const enum LayoutType {
    Default,
    DefaultSmallContent,
    ImageHeading,
    ImageHeadingCentered,
    Headless,
    ImageHeadingCenteredFullWidth,
    DefaultFullWidth,
    TvDefault
}

export default LayoutType;